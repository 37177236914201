@font-face {
  font-family: "fontello";
  src: url("fonts/fontello/fontello.eot?12375676");
  src: url("fonts/fontello/fontello.eot?12375676#iefix")
      format("embedded-opentype"),
    url("fonts/fontello/fontello.woff2?12375676") format("woff2"),
    url("fonts/fontello/fontello.woff?12375676") format("woff"),
    url("fonts/fontello/fontello.ttf?12375676") format("truetype"),
    url("fonts/fontello/fontello.svg?12375676#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?12375676#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  /* margin-right: .2em; */
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* margin-left: .2em; */

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow_left:before {
  content: "\e800";
} /* '' */
.icon-arrow_long_back:before {
  content: "\e801";
} /* '' */
.icon-arrow_long_forward:before {
  content: "\e802";
} /* '' */
.icon-arrow_right:before {
  content: "\e803";
} /* '' */
.icon-arrow_up:before {
  content: "\e804";
} /* '' */
.icon-backspace:before {
  content: "\e805";
} /* '' */
.icon-bag:before {
  content: "\e806";
} /* '' */
.icon-ball:before {
  content: "\e807";
} /* '' */
.icon-bcelone:before {
  content: "\e808";
} /* '' */
.icon-bell:before {
  content: "\e809";
} /* '' */
.icon-bewerage:before {
  content: "\e80a";
} /* '' */
.icon-camera:before {
  content: "\e80b";
} /* '' */
.icon-cards:before {
  content: "\e80c";
} /* '' */
.icon-caret_down:before {
  content: "\e80d";
} /* '' */
.icon-caret_left:before {
  content: "\e80e";
} /* '' */
.icon-caret_right:before {
  content: "\e80f";
} /* '' */
.icon-caret_up:before {
  content: "\e810";
} /* '' */
.icon-check:before {
  content: "\e811";
} /* '' */
.icon-code:before {
  content: "\e812";
} /* '' */
.icon-cog:before {
  content: "\e813";
} /* '' */
.icon-cog_f:before {
  content: "\e814";
} /* '' */
.icon-cross:before {
  content: "\e815";
} /* '' */
.icon-cube:before {
  content: "\e816";
} /* '' */
.icon-dashboard:before {
  content: "\e817";
} /* '' */
.icon-dashboard_f:before {
  content: "\e818";
} /* '' */
.icon-details_1:before {
  content: "\e819";
} /* '' */
.icon-details_2:before {
  content: "\e81a";
} /* '' */
.icon-details_3:before {
  content: "\e81b";
} /* '' */
.icon-details_4:before {
  content: "\e81c";
} /* '' */
.icon-eye:before {
  content: "\e81d";
} /* '' */
.icon-food:before {
  content: "\e81e";
} /* '' */
.icon-heart:before {
  content: "\e81f";
} /* '' */
.icon-home:before {
  content: "\e820";
} /* '' */
.icon-link:before {
  content: "\e821";
} /* '' */
.icon-lock:before {
  content: "\e822";
} /* '' */
.icon-menu:before {
  content: "\e823";
} /* '' */
.icon-minus:before {
  content: "\e824";
} /* '' */
.icon-money:before {
  content: "\e825";
} /* '' */
.icon-pen:before {
  content: "\e826";
} /* '' */
.icon-pen_draw:before {
  content: "\e827";
} /* '' */
.icon-permission:before {
  content: "\e828";
} /* '' */
.icon-permission_f:before {
  content: "\e829";
} /* '' */
.icon-plus:before {
  content: "\e82a";
} /* '' */
.icon-pos:before {
  content: "\e82b";
} /* '' */
.icon-products:before {
  content: "\e82c";
} /* '' */
.icon-search:before {
  content: "\e82d";
} /* '' */
.icon-sort:before {
  content: "\e82e";
} /* '' */
.icon-transactions:before {
  content: "\e82f";
} /* '' */
.icon-trash:before {
  content: "\e830";
} /* '' */
.icon-upload:before {
  content: "\e831";
} /* '' */
.icon-user:before {
  content: "\e832";
} /* '' */
.icon-user_f:before {
  content: "\e833";
} /* '' */
.icon-user_switch:before {
  content: "\e834";
} /* '' */
.icon-users:before {
  content: "\e835";
} /* '' */
.icon-users_f:before {
  content: "\e836";
} /* '' */
.icon-ad:before {
  content: "\e837";
} /* '' */
.icon-arrow_down:before {
  content: "\e838";
} /* '' */
.icon-table:before {
  content: "\e839";
} /* '' */
.icon-briefcase:before {
  content: "\e83a";
} /* '' */
.icon-location:before {
  content: "\e83b";
} /* '' */
.icon-building:before {
  content: "\f0f7";
} /* '' */
.icon-compass:before {
  content: "\f14e";
} /* '' */
.icon-building-filled:before {
  content: "\f1ad";
} /* '' */
